@import 'index.scss';
@import 'src/components/home/styles/home.scss';

.App {
  font-size: 1.2em;
}

.flex {
  display: flex;

  &.direction--column {
    flex-direction: column;
  }
}

.width--10 {
  width: 10em;
}

.nav-list {
  list-style: none;
  padding: 2em;

  li {
    padding: 0.5em;
  }
}

.nav {
  margin-top: 0;
  text-decoration: none;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.active-nav {
  color: rgba(206, 182, 182, 1);
  text-decoration: none;
  font-weight: bold;
}

@media screen and (max-width: 800px) {
 h1 {
    font-size: 4em;
 }
}
