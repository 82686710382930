.home-container {
    width: 100%;
}

h1 {
    color: rgba(206, 182, 182, 1);
    font-size: 4em;
    margin: 0;
}

.name-wrapper {
    width: 80%;
    margin: auto;
    margin-top: 2em;
}

.name-container {
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    flex-shrink: 0;
}

img {
    padding: 1em;
    width: 250px;
    height: auto;
    margin: 0;
}

.intro {
    margin-top: 1.5em;
}

@media screen and (max-width: 1050px) {
    h1 {
        font-size: 4em;
    }
}

@media screen and (max-width: 860px) {
    h1 {
        font-size: 3em;
        text-align: center;
    }

    img {
        width: 225px;
        height: auto;
    }

    .name-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // width: 80%;
    }

    .intro {
        width: 100%;
        text-align: center;
    }

    .about {
        width: 100%;
    }
  
}